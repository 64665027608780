:root {
  --borderColor: #e3e7eb;
  --primaryBlue: #6466f1;
  --primaryRed: #ff0000;
  --green: #38c356;
  --orange: #e49a0c;
  --subHeadingColor: #70777f;
  --grayBackground: #f4f6f8;
  --placeholderTextColor: #50576280;
  --gray: #767676;
  --lightGray: #000209;
  --lightBlue: #7588ff;
  --lightWhite: #ffffffe5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 1600px !important; */
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin-bottom: 0px !important;
}

/* table cells should not have padding */

/* th,
td {
  padding: 0 !important;
} */

.wheelDiv #wheel {
  height: 600px;
}

.analyticsModal .MuiModal-backdrop {
  backdrop-filter: blur(6px);
}

.css-1n89ibe-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #605bff !important;
}

table.customClass {
  width: 100%;
  border-spacing: 0;
}

table.customClass thead th {
  text-align: left;
}

table.customClass th,
table.customClass td {
  padding: 15px !important;
}

table.customClass tbody tr:hover {
  box-shadow: 1px 17px 44px 0px #0302290d;
}

table.customClass tbody tr td {
  border-bottom: 20px solid #0302290d;
  background: #fff;
}

table.customClass tbody tr:last-child td {
  border-bottom: 0;
}

.inputFormControl input {
  padding-block: 0;
  height: 40px;
  border-radius: 5px;
  background-color: white;
}

.inputFormControl .MuiFormControl-root {
  width: 100%;
}

p.MuiTypography-root {
  font-size: 14px !important;
}

input {
  font-size: 12px !important;
}

.variationsTable th {
  background-color: #f4f6f8;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: rgb(244, 244, 244);
}

.tdBorder .MuiInputBase-formControl {
  width: 80%;
  margin: auto;
  margin-bottom: 5px;
}

.primaryButton {
  height: 40px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.pageHeading {
  font-size: 14px;
  font-weight: 700;
  color: black;
}

.modalTitle {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.dialog .MuiDialog-paper {
  width: 1200px !important;
  max-width: 100%;
  height: 600px !important;
}

.secondaryButton {
  height: 40px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid #e3e7eb !important;
}

.MuiBox-root.css-110hhez {
  border-radius: 20px !important;
  border: 1px solid var(--borderColor);
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-aohcgg-MuiTableCell-root {
  padding: 6px 16px !important;
}

.MuiTableHead-root > .MuiTableCell-root {
  text-transform: capitalize !important;
  color: #70777f !important;
  font-weight: 500;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-1hfu1v0-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.select {
  height: 40px;
  background: white;
}

.boxShadow {
  box-shadow: 0px 1px 2px 0px #a4acb933;
  border-radius: 5px;
}

.firaFont {
  font-family: "Fira Code", monospace !important;
}

.css-18j3v2p-MuiCardContent-root:last-child {
  padding: 0 !important;
}

.cardSummary {
  font-size: 14px;
  font-weight: 400;
  font-family: "Fira Code", monospace !important;
}

.css-1oanhpn-MuiPaper-root-MuiAppBar-root {
  background-color: #6851ff !important;
}

.landingPageButton {
  color: #131f60 !important;
  border-radius: 30px !important;
  height: 40px !important;
  font-size: 16px !important;
  margin-top: 15px !important;
  background-color: #32de84 !important;
  /* optional */
  transition: color 0.3s !important;
}

.landingPageButton:hover {
  color: white !important;
  background-color: #1d1160 !important;
  /* optional: change background on hover */
}

.heroImg1 {
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
}

.heroImg2 {
  width: 200px;
  position: absolute;
  z-index: 2;
  bottom: 70px;
  right: 120px;
}

.heroImg3 {
  width: 120px;
  position: absolute;
  z-index: 5;
  bottom: 340px;
  right: 320px;
}

.heroImg4 {
  width: 100px;
  position: absolute;
  z-index: 2;
  bottom: 140px;
}

.heroImg5 {
  width: 100px;
  position: absolute;
  z-index: 2;
  bottom: 140px;
  right: 400px;
}

@media screen and (max-width: 640px) {
  .heroImg1,
  .heroImg2,
  .heroImg3,
  .heroImg4,
  .heroImg5 {
    transform: none !important;
  }

  .heroImg1 {
    width: 100%;
    object-fit: cover;
    position: unset;
  }

  .heroImg2 {
    right: 60px;
    bottom: 20px;
  }

  .heroImg3 {
    display: none;
  }

  .heroImg4 {
    left: 10px;
  }

  .heroImg5 {
    right: 40px;
  }
}

.css-1ms7hib-MuiTimelineItem-root::before {
  flex: none !important;
  content: "" !important;
}

.start-content__right {
  display: flex;
  justify-content: space-between;
  height: 600px;
  overflow: hidden;
}

ul.column.column--left {
  display: block;
  animation-duration: 55s;
}

.marquee-wrapper {
  display: flex;
  flex-flow: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  animation: 85s linear 0s infinite normal none running marqueeTop;
}

.column--middle.marquee-wrapper {
  animation: 100s linear 0s infinite normal none running marqueeBottom;
}

.marquee-wrapper img {
  display: block;
  height: auto;
  border-radius: 20px;
  width: 100% !important;
  margin-bottom: 15px !important;
  transition: transform 0.2s ease-in-out;
  transform: translateY(0px) !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.08) -2.95979px -1.97319px 7.39947px)
    drop-shadow(rgba(0, 0, 0, 0.08) 10.8526px 8.87936px 14.3056px);
}

@keyframes marqueeTop {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }

  100% {
    transform: translate3d(0px, -100%, 0px);
  }
}

@keyframes marqueeBottom {
  0% {
    transform: translate3d(0px, -100%, 0px);
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
