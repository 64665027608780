.regular_14 {
  font-size: 14px;
  font-weight: 500;
}

.regular_11 {
  font-size: 11px;
  font-weight: 600;
}

.regular_10 {
  font-size: 10px;
  font-weight: 500;
}

.regular_13 {
  font-size: 13px;
  font-weight: 400;
}

.regular_24 {
  font-size: 24px;
  font-weight: 700;
}

.regular_58 {
  font-size: 58px;
  font-weight: 800;
}

.regular_16 {
  font-size: 16px;
  font-weight: 500;
}

.regular_18 {
  font-size: 18px;
  font-weight: 600;
}
