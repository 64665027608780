@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

.fira-code {
  font-family: "Fira Code", monospace !important;
}

.logPage * {
  font-family: "Fira Code", monospace !important;
}

.redHat {
  font-family: "Red Hat Display", sans-serif;
}
